<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('GAME_MODE_DECK')}}</h1>
    <div class="content-container">
      <div class="first-section">
        <div class="col">
          <div class="col-title">{{ $t('PROPERTIES') }}</div>
          <div class="form-container">
            <p>{{ "Game Mode Type" }}</p>
            <select v-model.number="currentGameModeDeck.event_type">
              <option v-for="eventType in botDeckConstants.EVENT_TYPES" v-bind:key="eventType.value" :value="eventType.value">{{ eventType.text }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`MIRROR_DECK_VALUES.NAME`) }}</p>
            <input v-model="currentGameModeDeck.name"/>
          </div>
          <div class="form-container">
            <p>{{ $t(`MIRROR_DECK_VALUES.MIN_WIN`) }}</p>
            <input type="number" v-model="currentGameModeDeck.min_win">
          </div>
          <div class="form-container">
            <p>{{ $t(`MIRROR_DECK_VALUES.MAX_WIN`) }}</p>
            <input type="number" v-model="currentGameModeDeck.max_win">
          </div>
          <div class="form-container">
            <p>{{ $t(`MIRROR_DECK_VALUES.STATUS`) }}</p>
            <select v-model.number="currentGameModeDeck.status">
              <option v-for="status in botDeckConstants.STATUS" v-bind:key="status.value" :value="status.value">{{ status.text }}</option>
            </select>
          </div>
          <div class="form-container">
            <p>{{ $t(`BOT_DECK_VALUES.HERO_COOLDOWN_REDUCTION`) }}</p>
            <input type="number" v-model.number="currentGameModeDeck.hero_cooldown_reduction" />
          </div>
          <div class="form-container">
            <p>{{ $t(`BOT_DECK_VALUES.BOT_LEVEL`) }}</p>
            <select v-model.number="currentGameModeDeck.bot_level">
              <option v-for="botLevel in botDeckConstants.BOT_LEVEL" v-bind:key="botLevel.value" :value="botLevel.value">{{ botLevel.text }}</option>
            </select>
          </div>
          <div class="form-container form-container-inline">
            <p>{{ $t(`BOT_DECK_VALUES.ACTION_TIMER_RANGE`) }}</p>
            <input type="number" placeholder="min" v-model.number="currentGameModeDeck.min_action_time" />
            <input type="number" placeholder="max" v-model.number="currentGameModeDeck.max_action_time" />
          </div>
        </div>
        <div class="col">
          <div class="col-title">{{ $t('DECK') }}</div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`HERO`) }}</p>
              <select v-model="currentGameModeDeck.hero.id">
                <option v-for="hero in heroes" v-bind:key="hero.id" :value="hero.id">{{ hero.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.hero.level" />
            </div>
          </div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`UNIT`) + ' 1' }}</p>
              <select v-model="currentGameModeDeck.units[0].id">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.units[0].level" />
            </div>
          </div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`UNIT`) + ' 2' }}</p>
              <select v-model="currentGameModeDeck.units[1].id">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.units[1].level" />
            </div>
          </div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`UNIT`) + ' 3' }}</p>
              <select v-model="currentGameModeDeck.units[2].id">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.units[2].level" />
            </div>
          </div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`UNIT`) + ' 4' }}</p>
              <select v-model="currentGameModeDeck.units[3].id">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.units[3].level" />
            </div>
          </div>
          <div class="deck-item-container">
            <div class="form-container">
              <p>{{ $t(`UNIT`) + ' 5' }}</p>
              <select v-model="currentGameModeDeck.units[4].id">
                <option v-for="unit in units" v-bind:key="unit.id" :value="unit.id">{{ unit.name }}</option>
              </select>
            </div>
            <div class="form-container">
              <p>{{ $t(`LEVEL`) }}</p>
              <input type="number" v-model.number="currentGameModeDeck.units[4].level" />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button @click="submit" class="info">{{ $t('SUBMIT') }}</button>
        <template v-if="$route.query.id">
          <button v-if="env === 'staging' || env === 'prod'" class="info" @click="() => exportTo('dev')">Export to Development</button>
          <button v-if="env === 'dev' || env === 'prod'" class="info" @click="() => exportTo('staging')">Export to Staging</button>
          <button v-if="env === 'dev' || env === 'staging'" class="info" @click="() => exportTo('prod')">Export to Production</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onMounted, watch, onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import gameModeDeckSchema from "@/schemas/gameModeDeck";
import botDeckConstants from "@/constants/botDeckConstants";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "GameModeDeck",
  setup() {
    const store = useStore();
    const gameModeDecks = computed(() => store.getters['gameModeDecks/getGameModeDecks'])
    const units = ref([]);
    const heroes = ref([]);
    const currentGameModeDeck = reactive({ ...gameModeDeckSchema })
    const route = useRoute();

    const usedPages = ["gameModeDecks"];

    onBeforeMount(() => {
      usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    })
    })

    onMounted(() => {
      if(route.query.id && gameModeDecks.value.length > 0) {
        const editingGameModeDeck = JSON.parse(JSON.stringify(gameModeDecks.value.find(x => x.id === route.query.id)))
        Object.keys(editingGameModeDeck).forEach(key => {
          currentGameModeDeck[key] = editingGameModeDeck[key]
        })
      }
      store.dispatch("loader/loadingStatus", false)
    })

    watch(() => store.getters['gameModeDecks/getGameModeDecks'], gameModeDecks => {
      if(route.query.id && gameModeDecks.length > 0) {
        const editingGameModeDeck = JSON.parse(JSON.stringify(gameModeDecks.find(x => x.id === route.query.id)))
        Object.keys(editingGameModeDeck).forEach(key => {
          currentGameModeDeck[key] = editingGameModeDeck[key]
        })
      }
    })
    watch(() => store.getters['units/getUnits'], value => {
      units.value = value.filter(x => x.status === 1)
    }, {immediate: true});


    watch(() => store.getters['heroes/getHeroes'], value => {
      heroes.value = value.filter(x => x.status === 1)
    }, {immediate: true});
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    const submit = () => {
      if(route.query.id) {
        store.dispatch('gameModeDecks/updateGameModeDeck', { id: route.query.id, data: currentGameModeDeck })
      } else {
        store.dispatch('gameModeDecks/addGameModeDeck', { data: currentGameModeDeck })
      }
    }

    const exportTo = environment => {
      store.dispatch('gameModeDecks/exportGameModeDeck', { id: route.query.id, data: currentGameModeDeck, environment })
    }

    const appConfig = computed(() => store.getters['auth/getAppConfig'])
    const env = appConfig.value.appEnv || 'dev'

    return {
      currentGameModeDeck,
      botDeckConstants,
      submit,
      exportTo,
      env,
      units,
      heroes
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.first-section {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: start;
}

.form-container-inline {
  display: flex;
  align-items: center;
}

.form-container-inline p {
  margin: 15px 15px 15px 0;
}

.form-container-inline input {
  width: auto;
  margin: 0 10px;
}

p {
  margin-bottom: 0;
  font-size: 0.875rem;
}

input, textarea, select {
  width: calc(100% - 25px);
  padding: 5px 0;
}

.col-title {
  font-weight: 600;
  font-size: 1.125rem;
}

.col-subtitle {
  margin: 15px 0;
  font-weight: 600;
}

select {
  margin: 5px 0;
}

button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.deck-item-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

</style>